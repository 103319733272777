"use client"

import { PRODUCT_HUNT_REVIEWS_URL, ROUTES } from "@/constants/routes"
import { alpha, Box, Link, SxProps, Theme } from "@mui/material"
import Image from "next/image"
import { CustomButton } from "./buttons/CustomButton"
import Card from "./surfaces/Card"
import { Title, TitleSecondary } from "./typography/Title"
import SlideUpAnimation from "./utility/SlideUpAnimation"

export const BottomCTA = () => {
    return (
        <Card sx={styles.container}>
            <Box sx={styles.backgroundImage}>
                <Image
                    src="/svgs/pricing-cta-rect.svg"
                    alt="Pricing CTA"
                    width={630}
                    height={350}
                />
            </Box>

            <Box width={{ xs: "80%", md: "60%" }}>
                <SlideUpAnimation>
                    <Title sx={styles.title}>Summarize anything forget nothing</Title>
                </SlideUpAnimation>

                <SlideUpAnimation>
                    <Box sx={styles.ratingContainer}>
                        <Image src="/svgs/five-star.svg" alt="Pricing CTA" width={98} height={18} />
                        <Link href={PRODUCT_HUNT_REVIEWS_URL} target="_blank" sx={styles.link}>
                            <TitleSecondary sx={styles.ratingText}>
                                Rated 4.9 on Product Hunt
                            </TitleSecondary>
                        </Link>
                    </Box>
                </SlideUpAnimation>
            </Box>
            <Box>
                <SlideUpAnimation>
                    {/* @ts-ignore */}
                    <CustomButton target="_blank" href={ROUTES.SIGNUP}>
                        Get Started
                    </CustomButton>
                </SlideUpAnimation>
            </Box>
        </Card>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        width: "100%",
        position: "relative",
        height: "300px",
        background: (theme) => `
            linear-gradient(182deg, ${alpha(theme.palette.common.white, 0.1)} 1.21%, ${alpha(theme.palette.common.white, 0)} 98.63%), ${theme.palette.background.paper}
        `,
        borderRadius: 1.25,
        overflow: "hidden",
        display: "flex",
        justifyContent: { xs: "center", md: "space-between" },
        flexDirection: { xs: "column", md: "row" },
        px: { xs: 0, md: 12.5 },
        alignItems: "center",
        gap: 3,
        mb: 2,
    },
    backgroundImage: {
        display: { xs: "none", md: "block" },
        position: "absolute",
        top: 0,
        right: 50,
    },
    title: {
        pt: 0,
        fontSize: 50,
        lineHeight: "normal",
        pb: 5,
        textAlign: { xs: "center", md: "left" },
    },
    ratingContainer: {
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
    },
    ratingText: {
        fontSize: 16,
        fontWeight: 400,
        ml: 1.5,
        color: "text.primary",
    },
    link: {
        textDecoration: "none",
    },
}
