"use client"
import { alpha, Button, ButtonProps, CircularProgress, SxProps, Theme } from "@mui/material"
import { FC, ReactNode } from "react"

interface Props extends ButtonProps {
    disabled?: boolean
    onClick?: () => void | Promise<void> | null
    fullWidth?: boolean
    children: ReactNode
    mode?: "primary" | "secondary"
    isLoading?: boolean
}

export const CustomButton: FC<Props> = ({
    children,
    disabled,
    onClick,
    fullWidth = false,
    mode = "primary",
    isLoading = false,
    ...props
}) => {
    const isPrimary = mode === "primary"
    return (
        <Button
            disabled={disabled}
            sx={isPrimary ? styles.primaryButton : styles.secondaryButton}
            onClick={onClick}
            variant="contained"
            fullWidth={fullWidth}
            {...props}
        >
            {isLoading ? <CircularProgress size={20} color="inherit" /> : children}
        </Button>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    primaryButton: {
        lineHeight: 1,
        py: 1,
        fontSize: 16,
        height: 40,
        borderRadius: 0.5,
        backgroundColor: (theme) => theme.palette.primary.main,
        "&.Mui-disabled": {
            backgroundColor: (theme) => theme.palette.primary.main,
        },
    },
    secondaryButton: {
        lineHeight: 1,
        py: 1,
        height: 40,
        borderRadius: 0.5,
        fontSize: 16,
        color: (theme) => theme.palette.secondary.light,
        backgroundColor: (theme) => alpha(theme.palette.secondary.light, 0.2),
        border: (theme) => `1px solid ${alpha(theme.palette.secondary.light, 0.3)}`,
        "&:hover": {
            backgroundColor: (theme) => alpha(theme.palette.secondary.light, 0.3),
            borderColor: (theme) => alpha(theme.palette.secondary.light, 0.4),
        },
        "&.Mui-disabled": {
            backgroundColor: (theme) => theme.palette.primary.main,
        },
    },
}
