import { Box, SxProps, Theme } from "@mui/material"
import Link from "next/link"
import { PropsWithChildren } from "react"

interface GlowProps {
    color: string
    position: string
}

interface Props {
    sx?: SxProps<Theme>
    href?: string
    target?: string
    glow?: GlowProps
}

const getBackground = (glow: GlowProps | undefined, hover: boolean) => {
    let background =
        "linear-gradient(180deg, rgba(255, 255, 255, 0.06 ) 0%, rgba(255, 255, 255, 0.1) 100%)"
    let backgroundHover =
        "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)"

    if (glow && hover) {
        return `${backgroundHover}, radial-gradient(70% 70% at ${glow.position}, ${glow.color} 0%, rgba(255, 255, 255, 0) 100%)`
    } else if (glow && !hover) {
        return `${background}, radial-gradient(70% 70% at ${glow.position}, ${glow.color} 0%, rgba(255, 255, 255, 0) 100%)`
    } else if (!glow && hover) {
        return backgroundHover
    } else {
        return background
    }
}

const Card = ({ sx = {}, children, href, target, glow }: PropsWithChildren<Props>) => {
    const style = { ...styles.card, ...sx }

    if (href) {
        const linkStyle: SxProps<Theme> = {
            ...style,
            a: {
                textDecoration: "none",
                color: "text.primary",
            },
            background: getBackground(glow, false),
            "&:hover": {
                background: getBackground(glow, true),
            },
            cursor: "pointer",
            p: 1,
            borderRadius: 1,
            textAlign: "left",
        }

        return (
            <Box sx={linkStyle}>
                <Link
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                    href={href}
                    target={target}
                >
                    {children}
                </Link>
            </Box>
        )
    }
    return <Box sx={{ background: getBackground(glow, false), ...style, }}>{children}</Box>
}

const styles: Record<string, SxProps<Theme>> = {
    card: {
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        py: 4,
        px: 2,
        borderRadius: 2,
        boxShadow: "0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset",
    },
}
export default Card
